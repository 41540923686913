<template>
  <div class="notice">
    <!-- 备忘录详情页面 -->
    <Header
      back="返回"
      title="备忘录"
      index="首页"
      work_bench="工作台"
      beforeTitle="备忘录"
    />
    <!-- 备忘录详情内容 -->
    <div class="noticeText">
      <div
        class="textHead"
        v-loading="loading"
        element-loading-text="Loading···"
      >
        <h1>备忘录内容</h1>
        <div class="textName">
          <span>添加时间：{{ $store.state.memodetail.create_time }}</span>
        </div>
        <div class="textBody">
          <!-- <p class="bodyTitle"></p> -->
          <p>
            {{ $store.state.memodetail.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(["memodetail"]),
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
  },
  updated() {
    this.loading = false;
  },
  mounted() {
    this.$store
      .dispatch("getmemodetail", { id: this.$route.query.id })
      .then((res) => {
        console.log(this.$route.query.id, res, this.$store.state.memodetail);
      });
  },
  destroyed() {
    console.log("离开页面清空内容");
    this.memodetail.content = "";
    this.memodetail.create_time = "";
  },
};
</script>

<style scoped>
.notice {
  width: 100%;
  height: 100%;
}
.notice .noticeText {
  width: 95%;
  height: 100%;
  background: #fff;
  padding: 40px;
  margin-top: 15px;
}
.notice .noticeText .textHead h1 {
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
}
.notice .noticeText .textName span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #a6abc7;
  display: inline-block;
  margin: 14px 90px 22px 0;
}
.notice .noticeText .textBody p {
  line-height: 30px;
}

p::selection {
  background: #85ce61;
  border-color: #85ce61;
  color: #ffffff;
}
</style>
